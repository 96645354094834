<template>
  <div
    v-if="edit"
    :class="type === 'add' ? 'pb-10' : 'py-10'"
    class="md:full w-full mx-auto text-left"
  >
    <div class="mb-0">
      <h2
        :class="type === 'add' ? ' text-center' : 'text-left'"
        class="px-3 md:px-12 lg:px-8 xl:px-10 text-headerText font-medium text-lg md:text-2xl"
      >
        {{ type === "edit" ? " Edit Award" : "Add Award" }}
      </h2>
      <hr class="mt-4 border-0.5 border-dividerColor text-dividerColor" />
    </div>
    <form
      @submit.prevent="UpdateAward(true)"
      autocomplete="on"
      class="flex flex-col gap-y-1 px-3 md:px-12 lg:px-8 xl:px-10"
    >
      <!-- <label for="organization" class="text-dark-800 text-sm font-medium"
        >Awarding Organization
      </label> -->
      <easiTextInput
        placeholder="Organization Name"
        type="text"
        name="organization"
        class="mt-1 mb-3"
        v-model="args.awardingOrganisation"
        required
      ></easiTextInput>

      <!-- <label for="title" class="text-dark-800 text-sm font-medium"
        >Award Received
      </label> -->
      <easiTextInput
        placeholder="Title of Award Received"
        type="text"
        name="title"
        class="mt-1 mb-3"
        v-model="args.awardReceived"
        required
      ></easiTextInput>

      <label for="obtained" class="mt-4 text-dark-800 text-sm font-medium"
        >Date Obtained
      </label>
      <input
        class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mb-6"
        type="date"
        name="obtained"
        id="obtained"
        :max="today"
        v-model="args.dateObtained"
      />

      <UploadComponent @fileUrl="uploadFile = $event" :clear="removeFile" />

      <div
        v-if="type === 'edit'"
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <easiButton
          @click="edit = false"
          type="button"
          color="primary"
          variant="outlined"
          class="bg-white w-52"
        >
          Cancel
        </easiButton>
        <easiButton
          :loading="loading.edit"
          type="submit"
          color="primary"
          class=""
          :class="loading.edit ? 'w-52' : 'w-52'"
        >
          <span class="text-sm"> Update </span>
        </easiButton>
      </div>
      <!-- ////// Add Buttons ///// -->
      <div
        v-if="type === 'add'"
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <easiButton
          type="button"
          @click="save"
          color="primary"
          variant="outlined"
          class="w-80 md:w-44"
          :loading="loading.save"
          :class="loading.save ? 'pointer-events-none' : ''"
        >
          <span class="text-sm"> Save And Add More </span>
        </easiButton>
        <easiButton
          :loading="loading.add"
          type="submit"
          color="primary"
          class="w-80 md:w-44"
        >
          <span class="text-sm"> Submit </span>
        </easiButton>
      </div>
      <p
        v-if="type === 'edit'"
        @click="deleteWarning = true"
        class="cursor-pointer text-center text-sm font-medium text-dark-800 my-4"
      >
        Delete Award
      </p>
    </form>
    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span
          >Award {{ type === "edit" ? "updated" : "added" }} successfully</span
        >
      </template>
    </easiSuccess>

    <easiWarn v-if="deleteWarning" @close="deleteWarning = false">
      <template v-slot:message>
        <span>Are you sure you want to delete this award?</span>
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="flex justify-center items-center w-full gap-x-4 mt-6">
            <easiButton
              class="w-40 min-w-full"
              @click="deleteWarning = false"
              variant="outlined"
              >Cancel</easiButton
            >
            <easiButton
              class="w-40 min-w-full"
              :loading="loading.delete"
              @click="deleteEducationAward"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>
  </div>

  <ViewAward v-else @award="update($event)" />
</template>

<script setup>
import picIcon from "@/assets/icons/pic.svg";
import ViewAward from "@/components/Employee/View/Education/Award";
import UploadComponent from "@/components/global/UploadComponent";

import { useRoute } from "vue-router";
import {
  ref,
  reactive,
  nextTick,
  watch,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";

const { resetObj, processDate, uploadFileToServer } = helperFunctions;

const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees } = storeToRefs(store);
const toast = useToast();

const removeFile = ref(false);
const today = ref("");
today.value = processDate();
const route = useRoute();
const props = defineProps({
  type: {
    type: String,
    default: "edit",
  },
});
const emit = defineEmits(["complete"]);
const profileArgsFromStore = computed(() => store.profileData);

const loading = ref({
  add: false,
  edit: false,
  save: false,
  delete: false,
});
const uploadFile = ref(null);

const edit = ref(false);
let updateSuccess = ref(false);
const deleteWarning = ref(false);
const argsArray = ref([]);

const payload = reactive({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

const args = reactive({
  _id: null,
  awardReceived: "",
  awardingOrganisation: "",
  dateObtained: "",
  imageLink: "",
});

const employeeId = route.params.id;

async function queryEmployees() {
  await query({
    endpoint: "ListEmployees",
    payload: payload,
    service: "EMP",
    storeKey: "listEmployees",
  });
}
function getEmployeeById(id) {
  let employee = [];
  if (employeeId) {
    employee = getAllEmployees.value.data.filter((emp) => emp._id === id);
  }
  return employee;
}

async function addMore(arg) {
  if (uploadFile.value) {
    args.imageLink = await uploadFileToServer(uploadFile.value);
  }
  if (args.imageLink != null) {
    const newObj = Object.assign({}, args);

    argsArray.value.push(newObj);

    store.$patch((state) => {
      state.profileArr.push(newObj);
    });
    // toast.success("Details saved!");

    return args.imageLink;
  } else {
    toast.error("File upload failed");
    return false;
  }
}

async function save() {
  loading.value.save = true;
  try {
    let URL = await addMore(args);
    if (URL !== false) {
      await UpdateAward(false);
      Object.assign(args, resetObj(args, "args"));
      uploadFile.value = null;
      removeFile.value = true;
      toast.success("Details saved!");
    }
    loading.value.save = false;
  } catch (e) {
    console.log(e);
    loading.value.save = false;
  }

  // addMore(args);

  // window.location.reload();
}
function update(event) {
  edit.value = true;
  const arr = Object.keys(event);
  const assign = arr.map((key) => {
    args[key] = event[key];
    return event;
  });
  delete args["__typename"];
}

async function UpdateAward(final) {
  if (final) {
    loading.value[props.type] = true;
    let URL = await addMore(args);
  } else {
    loading.value.save = true;
  }

  // addMore(args);
  if (
    (args.imageLink != null && URL !== false && final) ||
    (!final && args.imageLink != null)
  ) {
    try {
      let res = await mutate({
        endpoint: "UpdateAward",
        data: {
          employeeId,
          input: profileArgsFromStore.value,
        },
        service: "EMP",
      });

      console.log(res);
      if (res[0].awardReceived.length) {
        await queryEmployees();
        // toast.success("Award Updated Successfuly");
        // args = resetObj(args, "args");
        Object.assign(args, resetObj(args, "args"));
        store.$patch({
          profileArr: [],
          pageIndex: 2,
        });

        if (final) {
          updateSuccess.value = true;

          setTimeout(() => {
            updateSuccess.value = false;
            edit.value = false;
            emit("complete", true);
            // window.location.reload();
          }, 500);
        }
      } else {
        loading.value[props.type] = false;
      }
    } catch (e) {
      loading.value[props.type] = false;
      console.log(e);
      args = resetObj(args, "args");
      store.$patch({
        profileArr: [],
      });
    } finally {
      loading.value[props.type] = false;
      loading.value.save = false;
    }
  }
}

queryEmployees();
const employees = getEmployeeById(route.params.id);
// assignValues();

const hide = ref(true);
let message = ref("");
let fileName = ref("");
let fileSize = ref("");

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

// let url = ref("");
function previewFiles(e) {
  const file = e.target.files[0];

  const allowedTypes = ["image/png", "image/gif", "image/jpeg"];
  if (!allowedTypes.includes(file.type)) {
    message.value = "File type is wrong!!";
    throw new Error(message.value);
  }
  if (file.size > 10000000) {
    message.value = "Too large, max size allowed is 10MB";
    throw new Error(message.value);
  }
  const { name, size } = file;
  fileName.value = name;

  fileSize.value = formatBytes(size);
  let reader = new FileReader();

  reader.onloadend = (e) => {
    // data url
    url.value = e.target.result;
    console.log(e.target.result, "K");
  };
  reader.readAsDataURL(file);
  // url = URL.createObjectURL(file);
}
onMounted(async () => {
  console.log(props.type, "PROPS");
  props.type === "add" ? (edit.value = true) : (edit.value = false);
  await queryEmployees();
  store.$patch({
    profileArr: [],
  });
});
onUnmounted(() => {
  store.$patch({
    profileArr: [],
  });
});

async function deleteEducationAward() {
  loading.value.delete = true;
  // let URL = await addMore(args);

  // addMore(args);
  const payload = {
    employeeId,
    educationAwardId: args._id,
  };
  try {
    let res = await mutate({
      endpoint: "DeleteEducationAward",
      data: payload,
      service: "EMP",
    });
    if (res) {
      // toast.success("Education History Updated Successfuly");
      updateSuccess.value = true;
      deleteWarning.value = false;
      await queryEmployees();

      setTimeout(() => {
        updateSuccess.value = false;
        edit.value = false;
        emit("complete", true);
        // window.location.reload();
      }, 500);
    } else {
      loading.value.delete = false;
    }
  } catch (e) {
    toast.error(e.message);
    console.log(e);
  } finally {
    loading.value.delete = false;
  }
}
</script>

<style></style>
